<template>
  <div class="page-break" v-if="studyUnit">
    <b-table-simple>
      <b-thead>
        <b-tr>
          <b-th
            colspan="5"
            style="text-align: justify"
            class="pl-2 py-1 unit-section-header"
          >
            <div class="container-header-ra">
              <div class="mr-2">
                <div
                  v-if="allows_crud && !studyUnit.study_unit_locked_view"
                  class="unit-section-move handle"
                  :class="{ 'grabbable-cursor': allows_crud }"
                >
                  <b-icon
                    class="move-icon"
                    icon="arrows-expand"
                    scale="1"
                  ></b-icon>
                </div>
                {{
                  $getVisibleNames(
                    "teaching.ramicro",
                    false,
                    "RA Micro"
                  ).toUpperCase()
                }}
                N°{{ getCompetenceUnitOrder(temp_competence_unit)
                }}{{ studyUnit.order }}
                {{ studyUnit.name.toUpperCase() }}
              </div>
              <div class="study-unit-button-div">
                <span
                  class="tag-study-unit secondary-color p-1 rounded mr-2"
                  v-if="studyUnit.study_environments.length > 0"
                >
                  (<span
                    v-for="(element, index) in studyEnvironments.filter((x) =>
                      studyUnit.study_environments.includes(x.id)
                    )"
                    :key="element.id"
                    >{{
                      studyUnit.study_environments.length != index + 1
                        ? element.name + ", "
                        : element.name
                    }}</span
                  >)
                  {{
                    $getVisibleNames(
                      "mesh.studyenvironment",
                      true,
                      "Ambientes de Aprendizaje"
                    )
                  }}
                </span>
                <span
                  class="tag-study-unit secondary-color p-1 mr-2 rounded"
                  v-if="studyUnit && studyUnit.hours > 0"
                >
                  {{ studyUnit.hours == null ? 0 : studyUnit.hours }}
                  Horas</span
                >
                <span
                  class="tag-study-unit secondary-color p-1 rounded"
                  :class="{
                    'bg-danger':
                      institution &&
                      institution.internal_use_id != 'duoc_uc' &&
                      parseFloat(studyUnit.weighing) == 0 &&
                      allows_crud,
                  }"
                  v-if="
                    matter &&
                    institution &&
                    ((!matter.automatic_achievement_weighing &&
                      institution.internal_use_id != 'ciisa_uss') ||
                      (institution.internal_use_id == 'ciisa_uss' &&
                        competenceUnit &&
                        !competenceUnit.automatic_achievement_weighing))
                  "
                >
                  Ponderación: {{ studyUnit.weighing }}%
                </span>
                <ButtonSmall
                  v-if="
                    allows_crud &&
                    studyUnit &&
                    !studyUnit.study_unit_locked_view
                  "
                  :tooltip_text="`Editar ${$getVisibleNames(
                    'teaching.ramicro',
                    false,
                    'RA Micro'
                  )}`"
                  :click_button="openModalStudyUnit"
                  :class="`ml-2`"
                  :icon="'square'"
                ></ButtonSmall>
                <!-- <button-edit
                  class="ml-2"
                  v-if="
                    allows_crud &&
                    studyUnit &&
                    !studyUnit.study_unit_locked_view
                  "
                  @click="openModalStudyUnit"
                  fill="white"
                  v-b-tooltip.v-secondary.noninteractive.top="
                    `Editar ${$getVisibleNames(
                      'teaching.ramicro',
                      false,
                      'RA Micro'
                    )}`
                  "
                ></button-edit> -->
              </div>
            </div>
          </b-th>
        </b-tr>
        <b-tr>
          <b-td
            class="text-center"
            :class="{
              'w-35':
                (institution && institution.internal_use_id == 'duoc_uc') ||
                $debug_change_duoc,
              'w-20': !(
                (institution && institution.internal_use_id == 'duoc_uc') ||
                $debug_change_duoc
              ),
            }"
          >
            <strong>
              {{
                $getVisibleNames(
                  "teaching.ramicro",
                  false,
                  "RA Micro"
                ).toUpperCase()
              }}
            </strong>
          </b-td>
          <b-td
            class="text-center"
            :class="{
              'w-35':
                (institution && institution.internal_use_id == 'duoc_uc') ||
                $debug_change_duoc,
              'w-30': !(
                (institution && institution.internal_use_id == 'duoc_uc') ||
                $debug_change_duoc
              ),
            }"
          >
            <strong>
              {{
                $getVisibleNames(
                  "manual.criterio_de_evaluacion",
                  true,
                  "Criterios de Evaluación"
                ).toUpperCase()
              }}
              <!-- CRITERIOS DE EVALUACIÓN -->
            </strong>
          </b-td>
          <b-td class="w-30">
            <div class="content-header">
              <div
                class="content-header d-flex justify-content-between align-items-center"
              >
                <div class="flex-grow-1 text-center font-weight-bold">
                  <span>
                    {{
                      $getVisibleNames(
                        "teaching.studyunitcontent",
                        true,
                        "Contenidos"
                      ).toUpperCase()
                    }}
                  </span>
                </div>
                <div v-if="allows_crud" class="btn-add-content">
                  <ButtonMedium
                    v-if="!studyUnit.study_unit_content_locked_view"
                    :tooltip_text="`Agregue los ${$getVisibleNames(
                      'teaching.studyunitcontent',
                      true,
                      'Contenidos'
                    )} por separado`"
                    :click_button="() => openModalContentCreate(studyUnit.id)"
                    :text_button="`Agregar`"
                    :custom_class="`my-1 mr-1`"
                    :icon="'plus'"
                    :variant="'primary'"
                  >
                  </ButtonMedium>
                  <!-- <b-button
                  v-if="!studyUnit.study_unit_content_locked_view"
                  v-b-tooltip.v-secondary.noninteractive="
                    `Agregue los ${$getVisibleNames(
                      'teaching.studyunitcontent',
                      true,
                      'Contenidos'
                    )} por separado`
                  "
                  @click="openModalContentCreate(studyUnit.id)"
                  variant="secondary"
                  size="sm"
                  class="my-1 mr-1"
                >
                  Agregar
                  {{
                    $getVisibleNames(
                      "teaching.studyunitcontent",
                      false,
                      "Contenido"
                    )
                  }}
                </b-button> -->
                  <b-modal
                    :id="`create-content-modal-${studyUnit.id}`"
                    :title="`Crear ${$getVisibleNames(
                      'teaching.studyunitcontent',
                      false,
                      'Contenido'
                    )}`"
                    size="lg"
                    hide-footer
                    ignore-enforce-focus-selector="#sidebar-right"
                    @hide="hideModaltoMatterProgram"
                  >
                    <NewRichTextEditor
                      @save="createContent"
                      @close="
                        $bvModal.hide(`create-content-modal-${studyUnit.id}`)
                      "
                    ></NewRichTextEditor>
                  </b-modal>
                </div>
              </div>
            </div>
          </b-td>
          <b-td
            v-if="
              !(
                (institution && institution.internal_use_id == 'duoc_uc') ||
                $debug_change_duoc
              )
            "
            colspan="1"
            class="text-center w-20"
          >
            <strong>{{
              $getVisibleNames(
                "mesh.methodologicalstrategy",
                true,
                "Estrategias Metodológicas"
              ).toUpperCase()
            }}</strong>
          </b-td>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td>
            <!-- <span>
              {{ getCompetenceUnitOrder(temp_competence_unit)
              }}{{ studyUnit.order }}</span
            > -->
            <div v-if="studyUnit" class="mx-1">
              <span
                :class="{
                  'pl-2': getCompetenceUnitOrder(temp_competence_unit) != null,
                }"
                ><SentenceContainer
                  class="w-100 sentence-criteria"
                  :Sentence="studyUnit"
                >
                  <template v-slot:order>
                    <span v-if="studyUnit">
                      {{ getCompetenceUnitOrder(temp_competence_unit)
                      }}{{ studyUnit.order }}
                    </span>
                  </template>
                </SentenceContainer>
              </span>
            </div>
          </b-td>
          <b-td>
            <template v-for="criteria in evaluationCriteriaList">
              <div :key="`criteria-${criteria.id}`" class="d-flex">
                <div class="d-flex w-100">
                  <!-- <span v-if="studyUnit" class="mx-1">
                    {{ getCompetenceUnitOrder(temp_competence_unit)
                    }}{{ studyUnit.order }}.{{ criteria.order }}
                  </span> -->
                  <SentenceContainer
                    class="w-100 sentence-criteria mb-2"
                    :Sentence="criteria"
                  >
                    <template v-slot:order>
                      <span v-if="studyUnit" class="mx-1">
                        {{ getCompetenceUnitOrder(temp_competence_unit)
                        }}{{ studyUnit.order }}.{{ criteria.order }}
                      </span>
                    </template>
                  </SentenceContainer>
                </div>
                <div
                  v-if="studyUnit && !studyUnit.automatic_achievement_weighing"
                  class="mx-1 text-center"
                >
                  {{ criteria.weighing }}%
                </div>
              </div>
            </template>
            <div v-if="allows_crud" class="text-center">
              <ButtonSmall
                v-if="studyUnit"
                :tooltip_text="`Editar ${$getVisibleNames(
                  'manual.criterio_de_evaluacion',
                  true,
                  'Criterios de Evaluación'
                )}`"
                :click_button="openModalEvaluationCriteria"
                :class="``"
                :icon="'square'"
              ></ButtonSmall>
              <!-- <button-edit
                v-if="studyUnit"
                @click="openModalEvaluationCriteria"
                v-b-tooltip.v-secondary.noninteractive="
                  `Editar ${$getVisibleNames(
                    'manual.criterio_de_evaluacion',
                    true,
                    'Criterios de Evaluación'
                  )}`
                "
              ></button-edit> -->
            </div>
          </b-td>
          <b-td>
            <draggable
              v-model="draggableList"
              :disabled="!allows_crud"
              tag="div"
              handle=".handle"
            >
              <!-- Contenidos con datos -->
              <template v-if="draggableList.length > 0">
                <template v-for="content in draggableList">
                  <div
                    class="d-flex align-items-center border-draggable"
                    :key="content.id"
                  >
                    <div
                      class="text-center handle"
                      :class="{ grabbable: allows_crud }"
                      v-if="
                        allows_crud && !studyUnit.study_unit_content_locked_view
                      "
                    >
                      <b-icon
                        v-if="allows_crud"
                        class="ml-1"
                        icon="arrows-expand"
                        scale="1.3"
                      ></b-icon>
                    </div>
                    <div :id="`content-${content.id}`" class="content-div">
                      <div
                        class="rich-text-content"
                        v-html="content.content"
                      ></div>
                      <!-- <EditableLabel
                        v-else
                        :Text="content.content"
                        @update="updateContent(content.id, arguments)"
                        class="textarea"
                      ></EditableLabel>

                      <b-popover
                        :target="`content-${content.id}`"
                        triggers="hover"
                        placement="right"
                        v-if="allows_crud"
                      >
                        <template>
                          <button-delete
                            @click="deleteContent(content.id)"
                          ></button-delete>
                        </template>
                      </b-popover> -->
                    </div>
                    <div v-if="allows_crud && content.id" class="text-center">
                      <ButtonSmall
                        v-if="!studyUnit.study_unit_content_locked_view"
                        tabindex="-1"
                        :tooltip_text="`Editar ${$getVisibleNames(
                          'teaching.studyunitcontent',
                          false,
                          'Contenido'
                        )}`"
                        :click_button="() => openModalContentEdit(content.id)"
                        :class="``"
                        :icon="'square'"
                      ></ButtonSmall>
                      <!-- <button-edit
                        v-if="!studyUnit.study_unit_content_locked_view"
                        tabindex="-1"
                        @click="openModalContentEdit(content.id)"
                        v-b-tooltip.v-secondary.noninteractive="
                          `Editar ${$getVisibleNames(
                            'teaching.studyunitcontent',
                            false,
                            'Contenido'
                          )}`
                        "
                      >
                      </button-edit> -->
                      <ButtonSmall
                        v-if="!studyUnit.study_unit_content_locked_view"
                        tabindex="-1"
                        :click_button="() => deleteContent(content.id)"
                        :tooltip_text="`Eliminar ${$getVisibleNames(
                          'teaching.studyunitcontent',
                          false,
                          'Contenido'
                        )}`"
                        :class="``"
                        :icon="'trash'"
                      ></ButtonSmall>
                      <!-- <button-delete
                        tabindex="-1"
                        v-if="!studyUnit.study_unit_content_locked_view"
                        @click="deleteContent(content.id)"
                        v-b-tooltip.v-secondary.noninteractive="
                          `Eliminar ${$getVisibleNames(
                            'teaching.studyunitcontent',
                            false,
                            'Contenido'
                          )}`
                        "
                      ></button-delete> -->

                      <b-modal
                        :id="`edit-content-modal-${content.id}`"
                        :title="`Editar  asda ${$getVisibleNames(
                          'teaching.studyunitcontent',
                          false,
                          'Contenido'
                        )}`"
                        size="lg"
                        hide-footer
                        ignore-enforce-focus-selector="#sidebar-right"
                        @hide="hideModaltoMatterProgram"
                      >
                        Copie y edite los
                        {{
                          $getVisibleNames(
                            "teaching.studyunitcontent",
                            true,
                            "Contenidos"
                          )
                        }}
                        por separado
                        <NewRichTextEditor
                          :Object="content"
                          :Text="content.content"
                          @save="updateContent"
                          @close="
                            $bvModal.hide(`edit-content-modal-${content.id}`)
                          "
                          :show_last_modification="true"
                          :object_last_modification="content"
                        ></NewRichTextEditor>
                      </b-modal>
                    </div>
                  </div>
                </template>
              </template>
            </draggable>
            <!-- <div
              class="new-content-div"
              title="Presione enter para insertar."
              v-if="allows_crud"
            >
              Inserte nuevo contenido aquí.
              <TextArea
                @update="createContent(study_unit_id, arguments)"
              ></TextArea>
            </div> -->
          </b-td>
          <b-td
            v-if="
              !(
                (institution && institution.internal_use_id == 'duoc_uc') ||
                $debug_change_duoc
              )
            "
          >
            <div class="wrapper">
              <ul
                :title="
                  $getVisibleNames(
                    'mesh.methodologicalstrategy',
                    true,
                    'Estrategias Metodológicas'
                  )
                "
                class="ul-methodological-strategies"
              >
                <li
                  v-for="strategy in selectedMethodologicalStrategies"
                  :key="strategy.id"
                >
                  {{ strategy.name }}
                </li>
              </ul>
            </div>
            <div
              v-if="allows_crud && !studyUnit.study_unit_locked_view"
              class="text-center"
            >
              <ButtonSmall
                v-if="studyUnit"
                :tooltip_text="`Editar ${$getVisibleNames(
                  'mesh.methodologicalstrategy',
                  true,
                  'Estrategias Metodológicas'
                )}`"
                :click_button="
                  () =>
                    $bvModal.show(
                      `modal-methodological-strategies-${study_unit_id}`
                    )
                "
                :class="``"
                :icon="'square'"
              ></ButtonSmall>
              <!-- <button-edit
                v-if="studyUnit"
                @click="
                  $bvModal.show(
                    `modal-methodological-strategies-${study_unit_id}`
                  )
                "
                v-b-tooltip.v-secondary.noninteractive="
                  `Editar ${$getVisibleNames(
                    'mesh.methodologicalstrategy',
                    true,
                    'Estrategias Metodológicas'
                  )}`
                "
              ></button-edit> -->
            </div>
          </b-td>
          <!-- <b-td>
            <div class="wrapper">
              <ul title="Instrumentos de Evaluación.">
                <li
                  v-for="instrument in selectedEvaluationInstruments"
                  :key="instrument.id"
                >
                  {{ instrument.value }}
                </li>
              </ul>
            </div>
            <div v-if="allows_crud" class="text-center">
              <button-edit
                v-if="studyUnit"
                @click="
                  $bvModal.show(`modal-evaluation-instrument-${study_unit_id}`)
                "
                v-b-tooltip.v-secondary.noninteractive="'Editar Instrumentos de Evaluación'"
              ></button-edit>
            </div>
          </b-td> -->
          <div>
            <b-modal
              v-if="studyUnit"
              :title="`Modificar ${$getVisibleNames(
                'teaching.ramicro',
                false,
                'RA Micro'
              )}`"
              size="xl"
              :hide-footer="true"
              :id="`edit-study-unit-modal-${study_unit_id}`"
              ignore-enforce-focus-selector="#sidebar-right"
              @hide="hideModaltoMatterProgram"
            >
              <StudyUnitForm
                :matter_id="matter.id"
                :egress_profile_matter_id="egress_profile_matter_id"
                :StudyUnit="studyUnit"
                :temp_competence_unit="temp_competence_unit"
                :allows_crud="allows_crud"
                :show_temp_competence_unit="true"
                :show_delete_button="allows_crud"
                @updated="slotUpdatedStudyUnit"
              ></StudyUnitForm>
            </b-modal>
            <b-modal
              v-if="studyUnit"
              :title="`Seleccionar ${$getVisibleNames(
                'mesh.methodologicalstrategy',
                true,
                'Estrategias Metodológicas'
              )}`"
              :id="`modal-methodological-strategies-${study_unit_id}`"
              size="lg"
              @hidden="closeMethodologicalStrategies(study_unit_id)"
            >
              <template v-slot:modal-header>
                <h5 class="custom-modal-title">
                  Seleccionar
                  {{
                    $getVisibleNames(
                      "mesh.methodologicalstrategy",
                      false,
                      "Estrategia Metodológica"
                    )
                  }}
                </h5>
                <InfoTooltip
                  class="selection-info ml-0"
                  tooltip_text="Para seleccionar múltiples elementos, mantenga presionada la tecla Ctrl y a continuación, seleccione los elementos que desee."
                >
                </InfoTooltip>
              </template>
              <b-input-group>
                <b-form-input
                  v-model="input_search"
                  type="search"
                  size="sm"
                  :placeholder="`Buscar ${$getVisibleNames(
                    'mesh.methodologicalstrategy',
                    true,
                    'Estrategias Metodológicas'
                  )}...`"
                >
                </b-form-input>
                <b-input-group-append>
                  <b-button
                    :disabled="!input_search"
                    @click="input_search = ''"
                    size="sm"
                    >Limpiar</b-button
                  >
                </b-input-group-append>
              </b-input-group>
              <small class="ml-1">
                Se muestran
                <span style="color: var(--kl-thirth-hover-color)"
                  >coloreadas</span
                >
                las
                {{
                  $getVisibleNames(
                    "mesh.methodologicalstrategy",
                    true,
                    "Estrategias Metodológicas"
                  )
                }}
                definidas como mínimas para la
                {{
                  $getVisibleNames(
                    "mesh.egressprofilematter",
                    false,
                    "Asignatura"
                  )
                }}.
              </small>
              <b-form-select
                v-model="methodological_strategies"
                text-field="name"
                value-field="id"
                multiple
                :select-size="leftMethodologicalStrategies.length"
              >
                <b-form-select-option
                  v-for="meth in matterMethodologicalStrategies"
                  :value="meth.id"
                  :key="meth.id"
                  style="background-color: var(--kl-thirth-hover-color)"
                  :title="`Estratégias Mínimas de la ${$getVisibleNames(
                    'mesh.egressprofilematter',
                    false,
                    'Asignatura'
                  )}`"
                  >{{ meth.name }}</b-form-select-option
                >
                <!-- style="background-color: #fa8b95" -->
                <b-form-select-option
                  v-for="meth in searchMethodologicalStrategies"
                  :value="meth.id"
                  :key="meth.id"
                  >{{ meth.name }}</b-form-select-option
                >
              </b-form-select>
              <template v-slot:modal-footer>
                <b-button
                  class="btn-cancel"
                  size="sm"
                  @click="closeMethodologicalStrategies(study_unit_id)"
                  >Cancelar</b-button
                >
                <b-button
                  size="sm"
                  @click="updateMethodologicalStrategies(study_unit_id)"
                  >Guardar</b-button
                >
              </template>
            </b-modal>
            <!-- <b-modal
              v-if="studyUnit"
              title="Seleccionar Instrumentos de Evaluación"
              :id="`modal-evaluation-instrument-${study_unit_id}`"
              ok-only
              @ok="updateEvaluationInstruments"
            >
              <small>
                Se muestran
                <span style="color: #fa8b95">coloreados</span>
                los Instrumentos de Evaluación definidos como mínimas para la
                {{
                  $getVisibleNames(
                    "mesh.egressprofilematter",
                    false,
                    "Asignatura"
                  )
                }}.
              </small>
              <b-form-select
                v-model="evaluation_instruments"
                text-field="name"
                value-field="id"
                multiple
                :select-size="evaluationInstruments.length"
              >
                <b-form-select-option
                  v-for="instrument in matterEvaluationInstruments"
                  :value="instrument.id"
                  :key="instrument.id"
                  :title="`Instrumentos Mínimos de la ${$getVisibleNames(
                    'mesh.egressprofilematter',
                    false,
                    'Asignatura'
                  )}`"
                  style="background-color: #fa8b95"
                  >{{ instrument.value }}</b-form-select-option
                >
                <b-form-select-option
                  v-for="instrument in leftEvaluationInstruments"
                  :value="instrument.id"
                  :key="instrument.id"
                  >{{ instrument.value }}</b-form-select-option
                >
              </b-form-select>
            </b-modal> -->
          </div>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <b-modal
      :title="
        $getVisibleNames(
          'manual.criterio_de_evaluacion',
          true,
          'Criterios de Evaluación'
        )
      "
      :id="`modal-evaluation-criteria-${study_unit_id}`"
      hide-footer
      size="lg"
      ignore-enforce-focus-selector="#sidebar-right"
      @hide="hideModaltoMatterProgram"
    >
      <EvaluationCriteria
        :allows_crud="allows_crud"
        :study_unit_id="study_unit_id"
      ></EvaluationCriteria>
    </b-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "UnitSection",
  components: {
    draggable,
    StudyUnitForm: () => import("@/components/reusable/StudyUnitForm"),
    // EditableLabel: () => import("@/components/reusable/EditableLabel"),
    // TextArea: () => import("@/components/reusable/TextArea"),
    EvaluationCriteria: () => import("../EvaluationCriteria"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
    ButtonMedium: () =>
      import("@/components/reusable/Buttons/Medium/ButtonMedium"),
    ButtonSmall: () =>
      import("@/components/reusable/Buttons/Small/ButtonSmall"),
  },
  props: {
    study_unit_id: {
      type: Number,
      required: true,
    },
    matter_id: {
      type: Number,
      required: true,
    },
    egress_profile_matter_id: {
      type: [Number, null],
    },
    temp_competence_unit: {
      type: [Number, null],
      default: null,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      methodological_strategies: [],
      evaluation_instruments: [],
      input_search: "",
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      matters: names.MATTERS,
      studyUnits: names.STUDY_UNITS,
      getStudyUnit: names.STUDY_UNIT,
      evaluationCriterias: names.EVALUATION_CRITERIAS,
      egressProfileMatters: names.EGRESS_PROFILES_MATTERS,
      evaluationInstruments: names.EVALUATION_INSTRUMENTS,
      studyEnvironments: names.STUDY_ENVIRONMENTS,
      contents: names.CONTENTS,
      methodologicalStrategies: names.METHODOLOGICAL_STRATEGIES,
      competence_units: names.COMPETENCE_UNITS,
    }),
    profileMatter() {
      return this.egressProfileMatters.find(
        (x) => x.id == this.egress_profile_matter_id
      );
    },
    matter() {
      if (!this.matter_id) return null;
      return this.matters.find((x) => x.id == this.matter_id);
    },
    studyUnit() {
      return this.getStudyUnit(this.study_unit_id);
    },
    competenceUnit() {
      if (!this.temp_competence_unit) return null;
      return this.competence_units.find(
        (x) => x.id == this.temp_competence_unit
      );
    },
    evaluationCriteriaList() {
      if (!this.studyUnit) return [];
      return this.evaluationCriterias
        .filter((x) => x.study_unit == this.studyUnit.id)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    contentsList() {
      return this.contents
        .filter((x) => x.study_unit == this.study_unit_id)
        .sort((a, b) => a.order - b.order);
    },
    draggableList: {
      get() {
        let list = this.contentsList;
        list.sort(function (a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) element.order = order;
          order += 1;
        });
        return list;
      },
      set(list) {
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) {
            element.order = order;
            if (!isNaN(element.id)) {
              this.updateOrderContent({
                content_id: element.id,
                item: { order: element.order },
              });
            }
          }
          order += 1;
        });
      },
    },
    selectedMethodologicalStrategies() {
      let list = [];
      if (!this.studyUnit) return list;
      this.studyUnit.methodological_strategies.forEach((element) => {
        let instance = this.methodologicalStrategies.find(
          (x) => x.id == element
        );
        if (instance) list.push(instance);
      });
      return list;
    },
    matterMethodologicalStrategies() {
      let list = [];
      if (!this.matter) return list;
      this.matter.methodological_strategies.forEach((element) => {
        let instance = this.methodologicalStrategies.find(
          (x) => x.id == element
        );
        if (instance) list.push(instance);
      });
      return list;
    },
    leftMethodologicalStrategies() {
      let list = this.methodologicalStrategies.filter(
        (x) =>
          (x.is_active || this.methodological_strategies.includes(x.id)) &&
          !this.matter.methodological_strategies.includes(x.id)
      );
      return list.sort(function (a, b) {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    },
    searchMethodologicalStrategies: function () {
      return this.leftMethodologicalStrategies
        .filter((item) => {
          return (
            item.name.toLowerCase().includes(this.input_search.toLowerCase()) ||
            this.methodological_strategies.includes(item.id)
          );
        })
        .sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
    },
    // selectedEvaluationInstruments() {
    //   let list = [];
    //   if (!this.studyUnit) return list;
    //   try {
    //     let evaluation_instruments = JSON.parse(
    //       this.studyUnit.evaluation_instruments
    //     );
    //     evaluation_instruments.forEach((element) => {
    //       let instance = this.evaluationInstruments.find(
    //         (x) => x.id == element
    //       );
    //       if (instance) list.push(instance);
    //     });
    //   } catch {
    //     return list;
    //   }
    //   return list;
    // },
    // matterEvaluationInstruments() {
    //   let list = [];
    //   if (!this.matter) return list;
    //   try {
    //     let evaluation_instruments = JSON.parse(
    //       this.matter.evaluation_instruments
    //     );
    //     evaluation_instruments.forEach((element) => {
    //       let instance = this.evaluationInstruments.find(
    //         (x) => x.id == element
    //       );
    //       if (instance) list.push(instance);
    //     });
    //   } catch {
    //     return list;
    //   }
    //   return list;
    // },
    // leftEvaluationInstruments() {
    //   let list = [];
    //   this.evaluationInstruments.forEach((element) => {
    //     if (!this.matterEvaluationInstruments.find((x) => x.id == element.id)) {
    //       list.push(element);
    //     }
    //   });
    //   return list;
    // },
  },
  methods: {
    openModalEvaluationCriteria() {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);

      if (this.profileMatter && this.studyUnit) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            egress_profile_id: this.profileMatter.egress_profile,
            egress_profile_matter_id: this.egress_profile_matter_id,
            competence_unit_id: this.studyUnit.temp_competence_unit,
            study_unit_id: this.study_unit_id,
            intelligence_option: 3,
          });
          this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
            visible_intelligence_options: [3],
            disabled_selected_egress_profile: true,
            disabled_selected_egress_profile_matter: true,
            disabled_selected_study_unit: true,
            disabled_selected_competence_unit: true,
          });
        });
      }
      this.$bvModal.show(`modal-evaluation-criteria-${this.study_unit_id}`);
    },
    openModalStudyUnit() {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);

      if (this.profileMatter && this.studyUnit) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            egress_profile_id: this.profileMatter.egress_profile,
            egress_profile_matter_id: this.egress_profile_matter_id,
            competence_unit_id: this.studyUnit.temp_competence_unit,
            intelligence_option: 2,
          });
          this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
            visible_intelligence_options:
              this.institution && this.institution.internal_use_id == "duoc_uc"
                ? [2, 7]
                : [2],
            disabled_selected_egress_profile: true,
            disabled_selected_egress_profile_matter: true,
            disabled_selected_competence_unit: true,
          });
        });
      }
      this.$bvModal.show(`edit-study-unit-modal-${this.study_unit_id}`);
    },
    openModalContentEdit(content_id) {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);

      this.$nextTick(() => {
        this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
          egress_profile_matter_id: this.egress_profile_matter_id,
          study_unit_id: this.study_unit_id,
          matter_id: this.egress_profile_matter_id,
          extra_label: `${this.$getVisibleNames(
            "teaching.studyunitcontent",
            false,
            "Contenido"
          )}`,
          extra_context: "",
          intelligence_option: 8,
          sub_option: 4,
        });
      });
      this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
        visible_intelligence_options: [8],
        disabled_selected_intelligence_option: true,
      });

      this.$bvModal.show(`edit-content-modal-${content_id}`);
    },
    openModalContentCreate(study_unit_id) {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);
      if (this.studyUnit) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            egress_profile_matter_id: this.egress_profile_matter_id,
            study_unit_id: this.studyUnit.id,
            matter_id: this.egress_profile_matter_id,
            extra_label: `${this.$getVisibleNames(
              "teaching.studyunitcontent",
              false,
              "Contenido"
            )}`,
            extra_context: "",
            intelligence_option: 8,
            sub_option: 4,
          });
        });
        this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
          visible_intelligence_options: [8],
          disabled_selected_intelligence_option: true,
        });
      }
      this.$bvModal.show(`create-content-modal-${study_unit_id}`);
    },

    hideModaltoMatterProgram() {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);

      if (this.profileMatter && this.studyUnit) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            egress_profile_id: this.profileMatter.egress_profile,
            egress_profile_matter_id: this.egress_profile_matter_id,
            competence_unit_id: null,
            intelligence_option: 2,
          });
          this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
            visible_intelligence_options: [2, 3, 4, 5, 6, 7],
            disabled_selected_egress_profile: true,
            disabled_selected_egress_profile_matter: true,
            disabled_selected_study_unit: null,
            disabled_selected_competence_unit: null,
          });
        });
      }
    },
    getCompetenceUnitOrder(temp_competence_unit_id) {
      const competence_unit = this.competence_units.find(
        (x) => x.id == temp_competence_unit_id
      );
      return competence_unit ? competence_unit.order + "." : null;
    },
    slotUpdatedStudyUnit(study_unit) {
      this.$bvModal.hide(`edit-study-unit-modal-${study_unit.id}`);
    },
    createContent(text) {
      // if (!args[0] || args[0] == "") return;
      if (text != "") {
        let item = {
          study_unit: this.study_unit_id,
          content: text,
          order: this.contentsList.length + 1,
        };
        this.$store.dispatch(names.POST_CONTENT, item);
      } else toast("No se puede guardar si está vacío.");
    },
    updateOrderContent(payload) {
      this.$store.dispatch(names.PATCH_CONTENT, payload);
    },
    updateContent(content, text) {
      // if (!args[0] || args[0] == "") {
      //   this.deleteContent(content_id);
      //   return;
      // }
      if (text != "") {
        const payload = {
          content_id: content.id,
          item: {
            content: text,
          },
        };
        this.$store.dispatch(names.PATCH_CONTENT, payload);
      } else toast("No se puede guardar si está vacío.");
    },
    deleteContent(content_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar el ${this.$getVisibleNames(
          "teaching.studyunitcontent",
          false,
          "Contenido"
        )}?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_CONTENT, content_id);
        }
      });
    },
    updateMethodologicalStrategies(study_unit_id) {
      let payload = {
        study_unit_id: this.studyUnit.id,
        item: {
          methodological_strategies: this.methodological_strategies,
        },
      };
      this.$store.dispatch(names.PATCH_STUDY_UNIT, payload).then((response) => {
        if (response)
          this.$bvModal.hide(
            `modal-methodological-strategies-${study_unit_id}`
          );
      });
    },
    closeMethodologicalStrategies(study_unit_id) {
      this.methodological_strategies = this.studyUnit.methodological_strategies;
      this.$bvModal.hide(`modal-methodological-strategies-${study_unit_id}`);
    },
    // updateEvaluationInstruments() {
    //   let payload = {
    //     study_unit_id: this.studyUnit.id,
    //     item: {
    //       evaluation_instruments: JSON.stringify(this.evaluation_instruments),
    //     },
    //   };
    //   this.$store.dispatch(names.PATCH_STUDY_UNIT, payload);
    // },
  },
  watch: {
    studyUnit: function () {
      this.methodological_strategies = this.studyUnit.methodological_strategies;
      if (this.evaluation_instruments.length > 0) {
        this.evaluation_instruments = JSON.parse(
          this.studyUnit.evaluation_instruments
        );
      }
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_STUDY_UNIT, this.study_unit_id);
    // .then((study_unit) => {
    //   study_unit.performance_indicators.forEach((indicator_id) => {
    //     this.$store.dispatch(names.FETCH_PERFORMANCE_INDICATOR, indicator_id);
    //   });
    // });
    //Se comento se está realizando cambios en evaluations_criterias.
    this.$store.dispatch(names.FETCH_EVALUATION_CRITERIAS, {
      study_unit_id: this.study_unit_id,
    });
    this.$store.dispatch(names.FETCH_CONTENTS, this.study_unit_id);
    // this.$store.dispatch(names.FETCH_EVALUATION_INSTRUMENTS);
    this.$store.dispatch(names.FETCH_METHODOLOGICAL_STRATEGIES);
  },
  mounted() {
    if (this.studyUnit) {
      this.methodological_strategies = this.studyUnit.methodological_strategies;
      try {
        this.evaluation_instruments = JSON.parse(
          this.studyUnit.evaluation_instruments
        );
      } catch {
        this.evaluation_instruments = [];
      }
    }
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.ul-methodological-strategies {
  padding-left: 0px !important;
}
.w-35 {
  width: 35% !important;
}
.w-20 {
  width: 20% !important;
}
.w-30 {
  width: 30% !important;
}
select option:checked {
  font-weight: bold !important;
  /* background-color: #cecece !important; */
}
table td {
  border: 1px solid black;
  text-align: left;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
table td:nth-child(3) {
  padding: 0;
}
table th {
  vertical-align: middle;
  background-color: var(--primary-color);
  color: white;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.container-header-ra {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.unit-section-header {
  color: black !important;
  background: var(--primary-color) !important;
  border: 1px solid #000 !important;
}
.content-wrapper {
  margin: 0;
  padding: 0;
}
.content-div {
  width: 100%;
  padding: 1em 0 0 1em;
  margin-bottom: 0.3em;
  text-align: justify;
}
.border-draggable {
  border-bottom: 1px solid var(--secondary-color);
}
.border-draggable:last-of-type {
  border-bottom: none !important;
}

/* .content-div:hover {
  background-color: var(--secondary-color);
} */
.new-content-div {
  margin: 2px 10px;
  padding: 2px 10px;
  bottom: 0;
  border: 1px solid var(--secondary-color);
}
p {
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
}
.wrapper {
  margin: 5px;
  padding: 5px;
}
li,
ul {
  text-align: left;
  vertical-align: middle;
}
.study-unit-button-div {
  float: right;
  padding-right: 1rem;
}
.select-option-div {
  background-color: var(--primary-color) !important;
  font-size: 8pt !important;
}
.sentence-criteria >>> p {
  padding: 0px !important;
}
.criteria {
  width: 80%;
  text-align: left;
  padding-left: 0.4em;
}
.custom-modal-title {
  display: block !important;
  width: 60%;
}
.selection-info {
  transform: translate(-84%, -20%);
  width: 40%;
  /* margin-right: auto;
  margin-left: 0 !important; */
}
.btn-cancel {
  margin-right: auto;
  margin-left: 0.3em;
}
.unit-section-content >>> ul,
.unit-section-content >>> ol {
  padding-left: 0.5rem !important;
}
.tag-study-unit {
  color: white !important;
}
.page-break {
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}
.unit-section-move {
  background: var(--primary-color);
  width: 25px;
  float: left;
}
.unit-section-move:hover {
  color: black;
}
.grabbable-cursor {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable-cursor:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.move-icon {
  margin: auto;
  cursor: grab;
}
.content-header {
  display: flex;
  width: 100% !important;
  border: none;
}
.content-header-text {
  margin-left: auto;
  /* vertical-align: middle !important; */
}
.content-header-text span {
  display: inline-block;
  vertical-align: middle;
  line-height: 3;
  font-weight: bold;
}
.btn-add-content {
  margin-left: auto;
  margin-right: 0;
}
@media (max-width: 991px) {
  .custom-modal-title {
    width: 80%;
  }
  .selection-info {
    transform: translate(-68%, -20%);
    width: 20%;
  }
}
</style>